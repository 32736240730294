import React, { useState } from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import { Banner, Layout, SubHeader } from 'components/global'
import { media } from 'utils/media'
import { ImageModal } from 'components/Modals'

const Body = styled.section`
  width: 100%;

  position: relative;
  background: var(--white);
  color: var(--black);
  padding-top: 3rem;
  .video {
    width: 90%;
    max-width: 1100px;
    margin: 0 auto;
  }

  .body {
    padding: 2rem 0;
    width: 90%;
    max-width: 1100px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 99;
    p {
      margin-bottom: 1rem;
      @media ${media.xl} {
        font-size: 20px;
      }
    }
    .text {
      margin: 4rem 0;
    }
    .image {
      width: 100%;
      height: auto;
      margin: 2rem 0;
      cursor: pointer;
      /* .gatsby-image-wrapper {
        width: 100%;
        height: auto;
        img {
          object-fit: fill;
        }
      } */
    }
  }
`

const FinalPage = () => {
  const [open, setOpen] = useState(false)
  return (
    <Layout>
      <SubHeader title="Arnold Clark Cup 2023" />
      <Body>
        <div className="body">
          <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe
              src="https://player.vimeo.com/video/810557498?h=731c637307&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
              }}
              title="07MAR23_ACC_WRAP_FINAL_16-9 (1).mp4"
            />
            <script src="https://player.vimeo.com/api/player.js" />
          </div>
          <div className="text">
            <p>
              The Arnold Clark Cup returned in 2023 with England, Belgium, Italy
              and Korea Republic battling it out for the Arnold Clark Cup
              trophy. Following the same double header match format as last
              year, the competition saw Stadium MK, Coventry Building Society
              Arena and Ashton Gate Stadium set the stage for several brilliant
              matchups.
            </p>
            <p>
              Over the six matches, the tournament delivered 23 goals with Chloe
              Kelly demonstrating her prolific goal scoring ability to win the
              Golden Boot. Notably, after three impressive performances, Lauren
              James was named Player of the Tournament.
            </p>
          </div>
          <div className="image" onClick={() => setOpen(true)}>
            <StaticImage
              src="../images/close/ACC-2023-Stats.jpg"
              alt="2023 Stats for Arnold Clark Cup"
            />
          </div>
          <div className="text">
            <p>
              The 2023 tournament proved to be an exciting spectacle for all the
              fans involved. Partnering with Superfoxx to bring feel-good party
              vibes at all three match days, the tournament achieved a total
              attendance figure of 100,579 and sold out two of the three venues.
              In particular, the Arnold Clark Cup match between England vs Italy
              set a new record for the most attended sporting event at the
              Coventry Building Society Arena.&nbsp;
            </p>
            <p>
              On the final match day, England retained the Arnold Clark Cup
              trophy by defeating Belgium in a 7-goal thriller at Ashton Gate
              Stadium.&nbsp;
            </p>
            <p>
              Thank you to everyone who supported the Arnold Clark Cup 2023, we
              will see you again soon!
            </p>
          </div>
          <div className="image">
            <StaticImage
              src="../images/close/ACC23-Infographic.png"
              alt="Infographic for Arnold Clark Cup"
            />
          </div>
        </div>
      </Body>
      <Banner />
      <ImageModal open={open} setOpen={setOpen}>
        <StaticImage
          src="../images/close/ACC-2023-Stats.jpg"
          alt="2023 Stats for Arnold Clark Cup"
        />
      </ImageModal>
    </Layout>
  )
}

export default FinalPage
